import React, { useState, useRef, useEffect } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition } from "@headlessui/react";
import "./DrawerComponent.css";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  content: React.ReactNode;
}

const DrawerComponent: React.FC<DrawerProps> = ({ isOpen, onClose, title, content }) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [startY, setStartY] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleStart = (event: React.MouseEvent | React.TouchEvent) => {
    const clientY = "touches" in event ? event.touches[0].clientY : event.clientY;
    setStartY(clientY);
    setIsDragging(true);

    // Remove transition for real-time dragging
    if (drawerRef.current) {
      drawerRef.current.style.transition = "none";
    }
  };

  const handleMove = (event: MouseEvent | TouchEvent) => {
    if (!isDragging || startY === null) return;

    const clientY = "touches" in event ? event.touches[0].clientY : event.clientY;
    const deltaY = clientY - startY;

    // Allow translation only downwards
    if (drawerRef.current) {
      drawerRef.current.style.transform = `translateY(${Math.max(0, deltaY)}px)`;
    }
  };

  const handleEnd = () => {
    if (!isDragging) return;

    if (drawerRef.current) {
      const drawerHeight = drawerRef.current.offsetHeight;
      const currentTranslateY = parseFloat(
        drawerRef.current.style.transform.replace("translateY(", "").replace("px)", "")
      );

      // Threshold for snapping behavior (adjustable)
      const snapThreshold = drawerHeight * 0.4; // 40% of the drawer height

      if (currentTranslateY > snapThreshold) {
        // If beyond the threshold, close the drawer (slide to bottom)
        drawerRef.current.style.transition = "transform 0.3s ease-in-out";
        drawerRef.current.style.transform = `translateY(${drawerHeight}px)`;
        setTimeout(onClose, 300); // Close after animation completes
      } else {
        // Otherwise, snap back to the top
        drawerRef.current.style.transition = "transform 0.3s ease-in-out";
        drawerRef.current.style.transform = "translateY(0)";
      }
    }

    setStartY(null);
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("touchmove", handleMove, { passive: false });
      document.addEventListener("mouseup", handleEnd);
      document.addEventListener("touchend", handleEnd);
    } else {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchend", handleEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchend", handleEnd);
    };
  }, [isDragging]);

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10 h-full bg-white" onClose={onClose}>
        <div className="fixed inset-0 bg-neutral-100 bg-opacity-50" />
        <div className="fixed inset-0 flex justify-center">
          <div
            ref={drawerRef}
            className="fixed inset-x-0 bottom-0 flex max-w-full h-[94%] modal-content slide-up transition-transform"
          >
            <Transition.Child
              as={React.Fragment}
              enter="transition-transform ease-in-out"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transition-transform ease-in-out"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <DialogPanel className="pointer-events-auto w-full max-w-md transform shadow-xl rounded-t-2xl bg-white">
                <div className="flex flex-col h-full">
                  <div
                    className="w-full flex justify-center py-3 pb-6"
                    onMouseDown={handleStart}
                    onTouchStart={handleStart}
                    style={{ cursor: "grab" }}
                  >
                    <div className="bg-topIcon w-9 h-[3px] rounded-full"></div>
                  </div>
                  <div className="px-4  pb-[25px] sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="textlarge700 text-neutral-900 font-serif text-center flex-1">
                        {title}
                      </DialogTitle>
                    </div>
                  </div>
                  <div className="relative flex-1 overflow-y-auto max-h-[85vh]">
                    <div className="p-4 textlarge400 font-serif">{content}</div>
                  </div>
                </div>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DrawerComponent;

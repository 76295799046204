import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { STATUS } from "../../../store/slices/AuthSlice";
import Header from "../../common/Header";
import BackIcon from "../../../assets/BackIcon";
import { resetPasswordAsync } from "../../../store/thunks/AuthThunk";
import AlertMessage from "../../common/AlertMessage";
interface EmailState {
    email: string;
}
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<EmailState>({
        email: ''
    });
    const [alertMessage, setAlert] = useState<{
        message: string;
        type: 'success' | 'error' | null;
        title: string;
    }>({
        message: '',
        type: null,
        title: '',
    });
    const [isEmailSent, setIsEmailSent] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const resetPasswordStatus = useSelector((state: RootState) => state.AuthStore.resetPasswordStatus);

    const handleResetPassword = async () => {
        dispatch(resetPasswordAsync(email))
    };
    const handleCloseAlert = () => {
        setAlert({ message: '', type: null, title: '' });
    };
    useEffect(() => {
        if (resetPasswordStatus === STATUS.SUCCESS) {
            setIsEmailSent(true);
        } else if (resetPasswordStatus === STATUS.FAILED) {
            setAlert({ message: 'Sending email failed, Please try again ', type: "error", title: 'Email not sent' });
        }
    }, [resetPasswordStatus, navigate]);
    return (
        <div className="flex flex-col pt-16 px-2.5 h-screen ">
            {!isEmailSent ? (
                <>
                    <div onClick={() => { navigate('/login') }}>  <BackIcon /></div>
                    <div className="mt-28"> <Header /></div>
                    <div className="texth2 flex justify-center">Reset your password.</div>
                    <div className="carouselSubTitle text-center mt-3">If you account exists, you’ll receive an email to reset your password.</div>
                    <div className="carouselSubTitle flex justify-start items-start mt-6 ml-1">Email</div>

                    <div>
                        <input
                            type="text"
                            value={email.email}
                            onChange={(e) => setEmail({ email: e.target.value })}
                            placeholder="Enter your email"
                            className="px-4 pr-10 py-3 w-full resize-none mt-2 rounded-md overflow-hidden border h-12 border-customSlate"
                        />
                    </div>
                    <button
                        className={`mt-6 bg-black text-white h-10 w-full rounded-full text-lg font-semibold ${!email.email ? "bg-gray-400" : "bg-loginBg text-white"}`}
                        onClick={handleResetPassword}
                        disabled={!email.email}
                    >
                        <div className="intertextmedium">Send reset link</div>
                    </button>
                </>
            ) : (
                <>
                    <div onClick={() => setIsEmailSent(false)}>
                        <BackIcon />
                    </div>
                    <div className="mt-60">
                        <Header />
                    </div>
                    <div className="texth2 flex justify-center">Email sent</div>
                    <div className="carouselSubTitle text-center mt-3">
                        Didn’t get an email?{" "}
                        <span
                            className="text-blue600 cursor-pointer"
                            onClick={handleResetPassword}
                        >
                            Resend
                        </span>
                    </div>
                </>
            )}
            {alertMessage.type && (
                <AlertMessage
                    open={!!alertMessage.type}
                    title={alertMessage.title}
                    message={alertMessage.message}
                    type={alertMessage.type}
                    onClose={handleCloseAlert}
                />
            )}
            {/* {loginDetails.loginStatus === STATUS.FAILED && <div className="text-red-500 mt-1 text-center">Login failed. Please check your credentials</div>} */}
        </div>
    );
}

export default ForgotPassword;

import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchUpcomingBillsAsync } from '../../store/thunks/TransactionThunk';
import { useSelector } from 'react-redux';

interface Bill {
    date: string;
    service: string;
    amount: string;
}



const UpcomingBills: FC = () => {
    const dispatch = useDispatch<AppDispatch>();


    useEffect(() => {
        const fetchUpcomingBills = async () => {
            await dispatch(fetchUpcomingBillsAsync());
        };

        fetchUpcomingBills();
    }, [dispatch]);

    const upcomingBills = useSelector((state: RootState) => state.TransactionStore.upcomingBills)


    return (
        <div className="px-0.5">
            <div className="relative bg-white pt-6 pl-6 pr-4 mt-3 rounded-2xl h-[158px] overflow-hidden group">
                <div className="text-lg font-bold">Upcoming bills</div>
                {/* Swipeable list */}
                <div className="mt-4 overflow-hidden">
                    {upcomingBills && upcomingBills.length > 0 ? (
                        <div className="flex space-x-4 overflow-x-auto scrollbar-hide">
                            {upcomingBills?.map((bill, index) => (
                                <div key={index} className="flex-shrink-0">
                                    {new Date(bill.nextBillDate).toLocaleString('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                    })}
                                    <div className="flex items-center px-4 py-2 rounded-lg border border-neutral-300">
                                        <div className="carouselSubTitle mr-2">{bill?.merchantName|| ''}</div>
                                        <div className="carouselSubTitle text-neutral-500">${bill.amount}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex justify-center items-center text-neutral-500 text-sm">No Upcoming Bills</div>
                    )}
                </div>
                <div className='absolute right-0 bottom-6 h-[52px] w-[74px] bg-gradient-to-l group-hover:opacity-0 from-white via-white to-transparent'>

                </div>

            </div>
        </div>
    );
};

export default UpcomingBills;

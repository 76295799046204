import AppLogo from "../../../assets/AppLogo";
import Carousel from "../../common/Carousel";
import image1 from "../../../assets/carouselImage1.svg";
import image2 from "../../../assets/carouselImage2.svg";
import image3 from "../../../assets/carouselImage3.svg";
import { useNavigate } from "react-router";
import Header from "../../common/Header";
const LandingState = () => {
    const navigate = useNavigate();
    const slides = [
        {
            title: 'Welcome to Finley',
            description: 'A wholistic approach to better financial wellness - with free, unlimited 1-on-1 coaching, covered by your employer.',
            image: image1, // Replace with your image URLs
        },
        {
            title: 'Easy, Automated Reminders',
            description: 'Keeping track of your money can be complicated - Finley makes it easier with timely reminders.',
            image: image2,
        },
        {
            title: 'Your money, without the stress',
            description: 'Correcting your financial habits can feel daunting. Finley learns your current habits and helps you build better ones.',
            image: image3,
        },
    ];

    return (
      
        <div className="flex flex-col bg-customBgColor m-3 overflow-hidden">
            <header className="sticky top-0 z-20">
            <Header />
            </header>
            <main className="flex-1 overflow-y-auto hide-scrollbar">
            <div className="text6 text-center flex justify-center mt-3 tracking-[-0.0075em]">A new way to manage your money.</div>

            <Carousel slides={slides} interval={10000} />
            {/* indicator dots */}
            <div className="text-center flex flex-col w-full mt-3 pb-3">
                <button className="bg-blackButton carouselSubTitle text-white py-2 px-6 rounded-full mb-2" onClick={() => navigate('/onboarding')}>
                    Get Started
                </button>
                <button className="border border-neutral300 carouselSubTitle text-black py-2 px-6 rounded-full" onClick={() => navigate('/login')}>
                    Sign In
                </button>
            </div>
            </main>
        </div>
    );
}
export default LandingState;
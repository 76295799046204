import { default as http } from "../rest/http";

export const login = async (data: any) => {
  try {
    const response = await http.post("/master-data/authentication/login", data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};



export const resetPassword = async (data: any) => {
  try {
    const response = await http.post("/master-data/account/reset-password", data);
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const createPassword = async (data: { password: string; confirmPassword: string }, token: string) => {
  try {
      const response = await http.putWithToken(
          "/master-data/account/reset-password",
          data,
          token
      );
      return response;
  } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
  }
};
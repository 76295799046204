import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GreetingHeader from '../../common/GreetingHeader';
import MonthlySpendLineChart from '../Charts/MonthlySpendLineChart';
import UpcomingBills from '../../common/UpcomingBills';
import CategorySpendingChart from '../Charts/CategorySpendingChart';
import Transactions from '../../common/Transactions';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchCategorySpendsAsync } from '../../../store/thunks/SpendingThunk';

export interface SpendingHomeProps {
  selectTab: (tabName: string) => void;
}

const SpendingHome: React.FC<SpendingHomeProps> = ({ selectTab }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [allTransactionFlag, setAllTransactionFlag] = useState(false);

  const categorySpentResponseState = useSelector(
    (state: RootState) => state.spendingStore.fetchCategorySpentResponse
  );


  // const [calculatedCategories, setCalculatedCategories] = useState<
  //   typeof categorySpentResponseState
  // >([]);

  useEffect(() => {
    dispatch(fetchCategorySpendsAsync());
  }, [dispatch]);

  // useEffect(() => {
  //   if (categorySpentResponseState) {
  //     const calculatedData = categorySpentResponseState.map((category) => ({
  //       ...category,
  //       allowedAmount: (category.categorySpend / category.spendPercentage) * 100,
  //     }));
  //     setCalculatedCategories(calculatedData);
  //   }
  // }, [categorySpentResponseState]);

  return (
    <div className="flex flex-col h-screen">
      {!allTransactionFlag && <GreetingHeader />}

      <main className={`flex-1 overflow-y-auto hide-scrollbar py-3 px-${allTransactionFlag ? 0 : 3}`}>
        <div className={!allTransactionFlag ? '' : 'hidden'}>
          <MonthlySpendLineChart />
          <div className="rounded-2xl border border-neutral-200 bg-white p-[25px]  text2">
            Spending by category
            <div className="flex flex-wrap justify-centre ">
              {categorySpentResponseState?.map((category) => (
                <div className="pt-[25px]" style={{ width: '25%' }} key={category.finleyCategory}>
                  <CategorySpendingChart
                    finleyCategory={category.finleyCategory}
                    spendPercentage={category.spendPercentage}
                    spendStatus={category.spendStatus}
                    spendRemaining={category.spendRemaining || 0}
                  />
                </div>
              ))}
            </div>
          </div>
          <UpcomingBills />
        </div>
        <div className='mb-28'>
          <Transactions
            allTransactionFlag={allTransactionFlag}
            setAllTransactionFlag={function (flag: boolean): void {
              setAllTransactionFlag(flag)
            }} />
        </div>
      </main>
    </div>
  );
};

export default SpendingHome;

import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData, setStepValid, OtpStatus, OnboardingState, validateStep } from "../../../store/slices/OnBoardingSlice";
import { AppDispatch, RootState } from "../../../store/store";
import { Input } from '@headlessui/react';
import { ResendOtpAsync, VerifyOtpAsync } from '../../../store/thunks/OnBoardingThunk';
import { MESSAGES } from '../../../types/CommonType';
import MessageComponent from '../../common/MessageComponent';

import { useNavigate } from 'react-router';
type StepProps = {
    onNext: () => void;
  };
  const Step2 = forwardRef<HTMLDivElement, StepProps>(({ onNext }, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const code = useSelector((state: RootState) => state.OnboardingStore.code);
    const email = useSelector((state: RootState) => state.OnboardingStore.email);
    const otpStatus = useSelector((state: RootState) => state.OnboardingStore.otpStatus);
    const onboarding = useSelector((state: RootState) => state.OnboardingStore.userOnboarding)
    const isValid: boolean | undefined = useSelector((state: RootState) => state.OnboardingStore.isValid.step2);
    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(true); // Initially disabled
    const [resendTimer, setResendTimer] = useState(0); // Initially no timer

    useEffect(() => {
        if (isVerifying && otpStatus === OtpStatus.SUCCESS) {
            setIsVerifying(false);
            const accessToken: string = onboarding?.accessToken;
            localStorage.setItem('accessToken', accessToken as string);
            if (onboarding.onboarding_state === OnboardingState.ACCOUNT_CREATED) {
                navigate('/new-connect-account')
            }
            else if (onboarding.onboarding_state === OnboardingState.BANK_ACCOUNT_CONNECTED) {
                navigate('/survey')
            }
            dispatch(validateStep({ step: 'step2', isValid: true }));
            onNext();
        } else if (isVerifying && otpStatus === OtpStatus.FAILED) {
            setIsVerifying(false);
            dispatch(setStepValid(false));
            setError(MESSAGES.OTP_VERIFICATION_FAILURE_MESSAGE);
        }
    }, [otpStatus, isVerifying, onNext, error, dispatch]);

    // Handle countdown for the "Resend code" button
    useEffect(() => {
        if (resendTimer > 0) {
            const timerId = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
            return () => clearTimeout(timerId);
        } else if (resendTimer === 0 && isResendDisabled ) {
            setIsResendDisabled(false); // Enable the "Resend" button when the timer ends
        }
    }, [resendTimer, isResendDisabled]);

    useEffect(() => {
        if (isValid) {
            setResendTimer(0); // Reset the timer to 0 or any initial value
            setIsResendDisabled(false); 
        }
    }, [isValid]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setError('');
        if (inputValue === '' || /^[0-9]{1,6}$/.test(inputValue)) {
            dispatch(updateFormData({ code: inputValue }));
            if (inputValue.length === 6) {
                dispatch(setStepValid(true)); // Mark step as valid when code is complete
            } else {
                dispatch(setStepValid(false)); // Mark step as invalid otherwise
            }
        }
    };

    const handleNext = async () => {
        await dispatch(VerifyOtpAsync({ email, otp: code }));
        setIsVerifying(true);
        setIsResendDisabled(true); // Disable the "Resend" button when the "Let's Go" button is clicked
        setResendTimer(30); // Start the timer for 30 seconds
    };

    const handleResend = async () => {
        //Dispatching the action to send the otp
        await dispatch(ResendOtpAsync({ email: email }));
        setIsResendDisabled(true);
        // Reset the timer to 30 seconds
        setResendTimer(30);
    };

    const renderErrorMessage = () => {
        return error && (
            <div className='pt-3'>
                <MessageComponent type="Error" message={error} />
            </div>
        );
    };

    return (
        <div 
        className={`transition-opacity duration-300 ${!isValid ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}` }>
             <div  ref={ref}></div>
            <div className="text3" >
                Thanks! I just sent you a one-time code to your email - please enter it below.
            </div>
            <div className='pt-3' >
                <Input
                    type="tel"
                    id="code"
                    value={code}
                    onChange={handleChange}
                    className="font-serif w-full text4 rounded-md h-[36px] pl-3 border border-slate-300 focus:border-slate-300 focus:outline-none"
                    placeholder="Enter the code"
                    maxLength={6}
                    required
                />
            </div>
            
            {renderErrorMessage()}
            <div className="w-full bg-customBgColor pt-3"  >
                <button
                    onClick={handleNext}
                    className={`flex justify-center items-center cursor-pointer w-full text3 text-white py-2 rounded-full ${code.length === 6 ? "bg-enabledButton" : "bg-disabledButton/50 cursor-not-allowed"
                        }`}
                    disabled={code.length !== 6}
                >
                    Let's Go
                </button>
            </div>
            <div className="intertext1 mt-3 pb-6"  >
                <button onClick={handleResend} disabled={isResendDisabled}>
                    {isResendDisabled ? `Resend code in ${resendTimer}s` : 'Resend code'}
                </button>
            </div>
          
        </div>
    );
});

export default Step2;



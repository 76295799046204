import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeepLinking from '../../hooks/useDeepLinking';
import { App } from '@capacitor/app';
const CountdownToClose = () => {
  //useDeepLinking();
  const navigate = useNavigate();

  useEffect(()=>{
  

App.addListener('appStateChange', ({ isActive }) => {
  console.log('App state changed. Is active?'+isActive);
});

App.addListener('appUrlOpen', data => {
  console.log('App opened with URL:'+ data);
});

App.addListener('appRestoredResult', data => {
  console.log('Restored state:'+ data);
});

const checkAppLaunchUrl = async () => {
  const x = await App.getLaunchUrl();

  console.log('App opened with URL: ' + x);
};
  })
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/new-connect-account'); // Redirect to the home page ("/")
    }, 10000); // 10000 ms = 10 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-2xl font-bold">Redirecting...</h1>
      <p className="text-lg">Closing the browser shortly...</p>
    </div>
  );
};

export default CountdownToClose;

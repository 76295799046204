import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { getUserAccountDetailsAsync } from '../../../store/thunks/MasterThunk';
import { useSelector } from 'react-redux';
import FinleyLogo from '../../../assets/FinleyLogo28.svg';
import carouselImage from "../../../assets/carouselHome.svg";
import user from "../../../assets/user.svg";
import bell from "../../../assets/bell.svg";
import ChatInput from '../../common/ChatInput';
import { setChatMessage } from '../../../store/slices/ChatSlice';
import { Button } from '@headlessui/react';
import { ArrowRight } from 'lucide-react';
import { fetchAllTransactionsAsync } from '../../../store/thunks/TransactionThunk';
import { useNavigate } from 'react-router';
import UpcomingBills from '../../common/UpcomingBills';
import Footer from '../../common/Footer';
import GreetingHeader from '../../common/GreetingHeader';
import Transactions from '../../common/Transactions';
import DrawerComponent from '../../common/DrawerComponent';
import BackIcon from '../../../assets/BackIcon';

export interface HomeTabSectionProps {
    selectTab: (tabName: string) => void;
}

export function HomeTabSection({ selectTab }: HomeTabSectionProps) {
    const buttonLabels = [
        'Boost my savings',
        'Help me budget',
        'Cut my spending',
        'Show my spending this month'
    ];
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {
        const fetchTransactionsData = async () => {
            await dispatch(fetchAllTransactionsAsync());
        };
        fetchTransactionsData();
    }, [dispatch]);
    const transactionData = useSelector((state: RootState) => state.TransactionStore.transactions)

    const masterData = useSelector((state: RootState) => state.MasterStore);


    const handleSend = (message: string) => {
        dispatch(setChatMessage(message));
        selectTab('Finley AI');
    };

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getUserAccountDetailsAsync());
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchTransactionsData = async () => {
            await dispatch(fetchAllTransactionsAsync());
        };
        fetchTransactionsData();
    }, [dispatch]);

    const closeModal = () => {
        setShowEditModal(false);
    };
    const content = (
        <div>
            <div className="bg-white  pt-16">
                <div className="flex  ">
                    <div onClick={() => { navigate('/home?from=Spending') }}> <BackIcon /></div>
                    <div className="carouselTitle mx-auto">All Transactions</div>
                </div>
                <hr className="mt-3"></hr>
                <div className="overflow-scroll h-screen px-3 pt-3">
                    {transactionData?.map((x, index) => (
                        <div
                            key={index}
                            className="w-full flex p-3 justify-between items-center self-stretch border border-borderGrey rounded-2xl my-2"
                        >
                            {/* Merchant Details */}
                            <div className="flex flex-col items-start">
                                <div className="text3">{x.merchant_name || "Unknown Merchant"}</div>
                                <div className="intertextxs text-neutral-500">{x.primary_category || "N/A"}</div>
                            </div>

                            {/* Transaction Details */}
                            <div className="flex flex-col items-end">
                                <div className="text3">
                                    {x.amount < 0
                                        ? `- $${Math.abs(x.amount)}`
                                        : `$${x.amount}`}
                                </div>
                                <div className="intertextxs text-neutral-500">{x.transaction_date}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

    return (
        <div className="flex flex-col h-screen">
            {/* Sticky Header */}
            <GreetingHeader />
          

            {/* Main Content Section with Scroll */}
            <div className="flex-1 overflow-y-auto hide-scrollbar px-3 ">
                <div className="py-3">
                    <ChatInput isLoading={false} send={handleSend} />
                </div>

                <div className="w-full overflow-x-auto hide-scrollbar">
                    <div className="flex space-x-1">
                        {buttonLabels.map((label, index) => (
                            <div key={index} className="flex-shrink-0">
                                <Button
                                    onClick={() => handleSend(label)}
                                    className="px-4 py-2 border-[0.5px] border-solid text-darkBlue bg-white rounded-full text3 border-neutral-300"
                                >
                                    {label}
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex mt-3 p-[25px] h-[452px] flex-col gap-[25px] self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white">
                    {/* Content above the button */}
                    <div>
                        <div className="flex pb-[25px] justify-center h-[224px]">
                            <img src={carouselImage} alt="home" />
                        </div>
                        <div className="text2">Let’s find you some money.</div>
                        <div className="pt-3 text3">
                            You can free up extra money in your budget with simple steps—it only takes 5 minutes.
                        </div>
                    </div>

                    {/* Button inside scrollable content */}
                    <div className="w-full">
                        <button
                            onClick={() => navigate('/new-connect-account')}
                            className="flex w-full items-center justify-center px-4 py-2 bg-neutral-900 text-white rounded-full space-x-1 text3"
                        >
                            <ArrowRight size={16} />
                            <span>Analyze my spending</span>
                        </button>
                    </div>
                </div>
            </div>
         
        </div>
    );
}


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountDetailAsync, fetchLinkTokenAsync, fetchPublicAccessTokenAsync } from "../../store/thunks/TransactionThunk";
import { AppDispatch, RootState } from "../../store/store";
import useDeviceInfo from '../../hooks/useDeviceInfo';
import { STATUS } from '../../types/CommonType';
import { Button } from "../ui/Button";
import { PlusIcon } from '@heroicons/react/20/solid';
import AlertMessage from '../common/AlertMessage';
import Spinner from '../common/Spinner';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';

const PlaidLinkComponent: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const deviceInfo = useDeviceInfo();
  const finleyUrl =  process.env.REACT_APP_PLAID_CALLBACK_URL+'callback'; 
  const { link_token, hostedLink, linkStatus,  } = useSelector(
    (state: RootState) => state.TransactionStore
  )

  const [alertMessage, setAlert] = useState<{
    message: string;
    type: 'success' | 'error' | null;
    title: string;
  }>({
    message: '',
    type: null,
    title: '',
  });

  const [isLinkOpened, setIsLinkOpened] = useState(false); // Track if the link has been opened

  useEffect(() => {
    if (link_token && hostedLink && !isLinkOpened) {
      const linkUrl = `${hostedLink}?link_token=${link_token}`;
      console.log('Plaid Link URL:', linkUrl);

      Browser.open({ url: linkUrl }).then(() => {
        setIsLinkOpened(true); // Mark as opened after the Browser opens the link
      });
    }
  }, [link_token, hostedLink, isLinkOpened]);

  // useEffect(() => {
  //   const handleRedirect = (event: any) => {
  //     alert('I am in redirect')
  //     const fetchAccounts = async () => {
  //       await dispatch(fetchAccountDetailAsync());
  //     };
  //     fetchAccounts();
  //   };

  //   App.addListener('appUrlOpen', handleRedirect);

  //   return () => {
  //     App.removeAllListeners();
  //     Browser.removeAllListeners();
  //   };
  // }, [dispatch]);
  App.addListener('appUrlOpen', (event) => {
    console.log('App URL Opened:', event.url);
  });

  const handleLinkConnect = async () => {
    let body: {
      is_mobile_app?: boolean;
      redirect_uri?: string;
      completion_redirect_uri?: string;
    } = {};

    if (deviceInfo?.platform !== 'web') {
      body = {
        // is_mobile_app: true,
        // redirect_uri: finleyUrl + 'callback',
        // completion_redirect_uri: "com.app.finley://" ,
        is_mobile_app: false,

        //completion_redirect_uri: finleyUrl + 'callback',
      };
    }
    else {
      body.is_mobile_app = false;
      body.completion_redirect_uri = finleyUrl ;
    }

    await dispatch(fetchLinkTokenAsync(body));
    setIsLinkOpened(false); // Allow link to be reopened when a new token is fetched
  };

  const handleCloseAlert = () => {
    setAlert({ message: '', type: null, title: '' });
  };
  useEffect(() => {
    let browserFinishedHandle: PluginListenerHandle | undefined;

    // Add listener for 'browserFinished' event
    const setupListeners = async () => {
      browserFinishedHandle = await Browser.addListener('browserFinished', () => {
        dispatch(fetchAccountDetailAsync());
        setIsLinkOpened(true); // Update state or trigger actions here
      
      });
   
      
    };

    setupListeners();

    // Cleanup listener on component unmount
    return () => {
      browserFinishedHandle?.remove();
    };
  }, []);

  return (
    <div className="flex justify-center items-center p-2 gap-2">
      {linkStatus === STATUS.LOADING ? (
        <Spinner />
      ) : (
        <Button
          onClick={handleLinkConnect}
          disabled={linkStatus === STATUS.LOADING}
        >
          <PlusIcon className="h-4 w-4 sm:h-5 sm:w-5" />
        </Button>
      )}

      {alertMessage.type && (
        <AlertMessage
          open={!!alertMessage.type}
          title={alertMessage.title}
          message={alertMessage.message}
          type={alertMessage.type}
          onClose={handleCloseAlert}
        />
      )}
    </div>
  );
};

export default PlaidLinkComponent;
